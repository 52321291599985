// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import 'node_modules/@ng-select/ng-select/themes/material.theme.css';

// import roboto from local
$roboto-font-path: '~roboto-fontface/fonts' !default;
@import 'node_modules/roboto-fontface/css/roboto/sass/roboto-fontface';

// import material icons from local
$material-icons-font-path: '~material-icons/iconfont/';
@import 'node_modules/material-icons/iconfont/material-icons.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$tim-accent: (
  default: #4586e8,
  lighter: #6ebbfd,
  darker: #3990dd,
  contrast: (
    default: #ffffff,
    lighter: #ffffff,
    darker: #ffffff,
  ),
);

$tim-primary: (
  default: #000000,
  lighter: #000000,
  darker: #000000,
  contrast: (
    default: #ffffff,
    lighter: #ffffff,
    darker: #ffffff,
  ),
);

// $tim-warn: (
//   default: #bc4225,
//   lighter: #bc4225,
//   darker: #bc4225,
//   contrast: (
//     default: #ffffff,
//     lighter: #ffffff,
//     darker: #ffffff,
//   ),
// );

$tim-ui-primary: mat.define-palette($tim-primary, default, lighter, darker);
$tim-ui-accent: mat.define-palette($tim-accent, default, lighter, darker);
$background-color: #f9f9f9;

// The warn palette is optional (defaults to red).
// $tim-ui-warn: mat.define-palette($tim-warn, default, lighter, darker);

// Create the theme object (a Sass map containing all of the palettes).
$tim-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $tim-ui-primary,
      accent: $tim-ui-accent,
    ),
  )
);
$background: map-get($tim-ui-theme, background);
$background: map_merge(
  $background,
  (
    background: #f9f9f9,
  )
);
$tim-ui-theme: map_merge(
  $tim-ui-theme,
  (
    background: $background,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.button-theme($tim-ui-theme);
@include mat.all-component-themes($tim-ui-theme);
@import 'assets/app-scss/font-size-mixin';
@import 'assets/app-scss/tim-material-card';

/* You can add global styles to this file, and also import other style files */

html {
  @include font-size(16);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto;
}

.mat-mdc-form-field-error {
  font-size: 0.7rem;
  letter-spacing: normal;
  word-spacing: normal;
}

.mat-mdc-form-field {
  font-size: 0.9rem !important;
  margin-top: 4px;
}

.mat-mdc-select-value {
  font-size: 0.9rem;
}

.ng-select .ng-select-container {
  min-height: 56px;
  min-width: 200px;
}

.ng-clear-wrapper,
.ng-arrow-wrapper {
  padding-bottom: 4px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 56px;
  width: 200px;
}

.mat-mdc-form-field-infix {
  padding-bottom: 10px !important;
}

.mat-mdc-button,
.mdc-button {
  letter-spacing: normal;
}

.mat-mdc-card {
  padding: 1rem;
}

.mat-standard-chip.mat-chip-with-trailing-icon {
  background-color: rgb(227, 226, 226) !important;
}

.ng-busy {
  z-index: 1002;
}

.ng-busy,
.ng-busy > *,
.ng-busy > ng-component > * {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.ng-busy-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background: #fff;
  opacity: 0.7;
}
.ng-busy-default-wrapper {
  text-align: center;
}
.ng-busy-default-sign {
  position: relative;
  display: inline-block;
  z-index: 1003;
  padding: 12px 14px;
  border: 1px solid #d8d8d8;
  border-top: 0;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background: #f8f8f8;
  color: #333;
}
.ng-busy-default-text {
  display: inline-block;
  margin-left: 6px;
  max-width: 400px;
  font-size: 14px;
  text-align: left;
}
.ng-busy-default-spinner {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
.ng-busy-default-spinner div {
  position: absolute;
  left: 44.5%;
  top: 37%;
  width: 10%;
  height: 26%;
  background: #666;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: busy-spinner-anim 1s linear infinite;
}
.ng-busy-default-spinner .bar1 {
  transform: rotate(0deg) translate(0, -142%);
  animation-delay: -1s;
}
.ng-busy-default-spinner .bar2 {
  transform: rotate(30deg) translate(0, -142%);
  animation-delay: -0.91666667s;
}
.ng-busy-default-spinner .bar3 {
  transform: rotate(60deg) translate(0, -142%);
  animation-delay: -0.83333333s;
}
.ng-busy-default-spinner .bar4 {
  transform: rotate(90deg) translate(0, -142%);
  animation-delay: -0.75s;
}
.ng-busy-default-spinner .bar5 {
  transform: rotate(120deg) translate(0, -142%);
  animation-delay: -0.66666667s;
}
.ng-busy-default-spinner .bar6 {
  transform: rotate(150deg) translate(0, -142%);
  animation-delay: -0.58333333s;
}
.ng-busy-default-spinner .bar7 {
  transform: rotate(180deg) translate(0, -142%);
  animation-delay: -0.5s;
}
.ng-busy-default-spinner .bar8 {
  transform: rotate(210deg) translate(0, -142%);
  animation-delay: -0.41666667s;
}
.ng-busy-default-spinner .bar9 {
  transform: rotate(240deg) translate(0, -142%);
  animation-delay: -0.33333333s;
}
.ng-busy-default-spinner .bar10 {
  transform: rotate(270deg) translate(0, -142%);
  animation-delay: -0.25s;
}
.ng-busy-default-spinner .bar11 {
  transform: rotate(300deg) translate(0, -142%);
  animation-delay: -0.16666667s;
}
.ng-busy-default-spinner .bar12 {
  transform: rotate(330deg) translate(0, -142%);
  animation-delay: -0.08333333s;
}
@keyframes busy-spinner-anim {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}

[hidden] {
  display: none !important;
}
